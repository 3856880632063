<template>
  <div>
    <div v-if="!loading">
      <div v-if="!error" class="container mb-5">
        <div class="row">
          <div class="col-md-4 offset-md-4">
            <img
              src="@/assets/images/newsletter/msg-check.png"
              style="width: 100%"
            />
          </div>
        </div>
        <h1 class="text-center mb-5" style="color: #97c03b; font-size: 4rem">
          Your email address has been
          <br />confirmed, thank you!
        </h1>
        <div>
          <h3 class="mb-3" style="color: #97c03b">
            You can now keep up to date with all the latest health news and
            insights from Amchara Health Retreats.
          </h3>
          <p>
            Requested a free report or subscribed to our newsletter? Your emails
            should arrive in your inbox shortly.
          </p>
          <p>
            If for any reason you would like to stop receiving emails from us,
            there is an 'unsubscribe' link at the bottom of each email we send. If
            you have any questions or concerns, please don’t hesitate to contact
            us.
          </p>
          <p>
            You can make an enquiry
            <router-link to="/enquiries">here</router-link>, email us at
            <a href="mailto:change@amchara.com">change@amchara.com</a> or call us
            at
            <b>+44 (0) 1823 213 111.</b>
          </p>
          <b style="color: #97c03b">
            <i>We’d love to speak with you!</i>
          </b>
        </div>
        <a
          href="https://amchara360.com/complimentary-consultation?utm_source=emailthankyoupage&utm_medium=textlink"
          ><img
            src="@/assets/images/newsletter/mid-360.png"
            style="width: 100%"
            class="my-5"
            border="0"
        /></a>
      </div>

      <div v-else class="container mb-5">
        <div class="row text-center my-4">
          <h3 class="my-4" style="color: #97c03b">
            {{ error }}
          </h3>
          <div>
            <p>
              If you feel this is a mistake or have any questions, please feel free
              to contact us.
            </p>
            <p>
              You can make an enquiry
              <router-link to="/enquiries">here</router-link>, email us at
              <a href="mailto:change@amchara.com">change@amchara.com</a> or call us
              at
              <b>+44 (0) 1823 213 111.</b>
            </p>
          </div>
          <a
            href="https://amchara360.com/complimentary-consultation?utm_source=emailthankyoupage&utm_medium=textlink"
            ><img
              src="@/assets/images/newsletter/mid-360.png"
              style="width: 100%"
              class="my-5"
              border="0"
          /></a>
        </div>
      </div>
    </div>

    <div v-else class="container m-5">
      <div class="row">
        <div class="col-md-4 offset-md-4">
          Loading..
        </div>
      </div>
    </div>

    <featured-mags></featured-mags>
    <testimonials></testimonials>
  </div>
</template>

<script>
import FeaturedMags from "./page-partials/FeaturedMags";
import Testimonials from "./page-partials/Testimonials";
export default {
  data() {
    return {
      error: null,
      loading: true,
    }
  },
  metaInfo() {
    return {
      vmid: this.$route.path,
      name: this.$route.path,
      title: this.$store.seo_metas.find(
        (i) => i.slug == this.$route.path.replace("/", "")
      ).title,
      content: this.$store.seo_metas.find(
        (i) => i.slug == this.$route.path.replace("/", "")
      ).description,
      // titleTemplate: null,
    };
  },
  mounted() {
    this.$axios
      .post(process.env.VUE_APP_API_URL + "/newsletter-confirm", { token: this.$route.query.token })
      .then(({ data }) => {
        this.loading = false;

        if (data.color && data.color === "danger") {
          this.error = data.message;
        }
      });
  },
  components: {
    FeaturedMags,
    Testimonials,
  },
};
</script>

<style>
</style>